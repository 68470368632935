import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';

const ArticleList = ({ articles, chosenArticlePath }) => {
  const { isMobile, isMobileStructureOpen, toggleMobileStructure } = useResponsive();
  const { isAdmin } = useAuth();

  const handleListClick = () => {
    if (isMobileStructureOpen) {
      return;
    }

    toggleMobileStructure(true);
  };

  const handleListItemClick = (event) => {
    if (isMobile && !isMobileStructureOpen) {
      event.preventDefault();

      return;
    }

    toggleMobileStructure(false);
  };

  return (
    <ul
      className="grow shrink-0 flex flex-col md:w-60 border-purple-primary border-r-2 overflow-auto"
      onClick={handleListClick}
    >
      {articles.map(({ title, path, isPublic }) => (
        <li
          key={path}
          className={cn('shrink-0 font-bold border-b border-purple-primary', {
            'bg-purple-100': path.toLowerCase() === chosenArticlePath.toLowerCase(),
            'opacity-60': !isPublic && !isAdmin,
          })}
          data-testid={`${title}-article-list-item`}
        >
          <Link
            className="block p-4 hover:no-underline"
            to={`/compendium/${path}`}
            onClick={handleListItemClick}
            data-testid={`${title}-article-link`}
          >
            {title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ArticleList;
