import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useSnackbar } from 'notistack';
import cn from 'classnames';

import Modal from '../Modal';
import useAuthModal from '../../hooks/useAuthModal';
import SignInForm from '../SignInForm';
import SignUpForm from '../SignUpForm';
import useAuth from '../../hooks/useAuth';
import { signOut } from '../../restApi/auth';
import './index.scss';

const Authentication = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const { user, deauthenticateUser } = useAuth();
  const { isModalOpen, closeModal } = useAuthModal();
  const { enqueueSnackbar } = useSnackbar();

  const handleSignOut = async () => {
    try {
      await signOut();

      deauthenticateUser();
      enqueueSnackbar('Success', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.response?.data.message || error.message, { variant: 'error' });
    } finally {
      closeModal();
    }
  };

  return (
    <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          {!user ? (
            <>
              <div className="flex gap-4">
                {['Sign In', 'Sign Up'].map((label, i) => (
                  <button
                    key={label}
                    type="button"
                    className={cn({ underline: i === currentTab }, 'uppercase')}
                    onClick={() => setCurrentTab(i)}
                  >
                    {label}
                  </button>
                ))}
              </div>
              {currentTab === 1 ? (
                <SignUpForm closeModal={closeModal} />
              ) : (
                <SignInForm closeModal={closeModal} />
              )}
            </>
          ) : (
            <button type="button" className="uppercase" onClick={handleSignOut}>
              Sign Out
            </button>
          )}
        </Modal>
      )}
    </AnimatePresence>
  );
};

export default Authentication;
