import React, { useState, useEffect } from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import { motion } from 'framer-motion';

import ArticleList from '../ArticleList';
import CompendiumStructure from '../CompendiumStructure';
import useResponsive from '../../hooks/useResponsive';

const prepareStructure = (value) =>
  value.map((item) => ({
    ...item,
    isOpen: false,
  }));

const structureDropIn = {
  hidden: {
    x: '-92vw',
  },
  visible: {
    x: 0,
  },
};

const compendiumMetadataQuery = graphql`
  query {
    compendium {
      metadata {
        structure {
          name
          path
          subsections {
            name
            path
            articles {
              path
              title
              isPublic
            }
          }
        }
      }
    }
  }
`;

const getSubsectionFromStructure = (structure, section, subsection) => {
  const sectionObject = structure.find(
    (structureSection) => structureSection.path.toLowerCase() === section.toLowerCase(),
  );

  if (!sectionObject) {
    throw new Error('Unexisting section');
  }

  const subsectionObject = sectionObject.subsections.find(
    (structureSubsection) =>
      structureSubsection.path.toLowerCase() === `${section}/${subsection}`.toLowerCase(),
  );

  if (!subsectionObject) {
    throw new Error('Unexisting subsection');
  }

  return subsectionObject;
};

const getInitialChosenSection = (...args) => {
  const emptySection = {
    articles: [],
    name: null,
    path: null,
  };

  try {
    return getSubsectionFromStructure(...args);
  } catch {
    return emptySection;
  }
};

const CompendiumNavigation = ({ children, pathname }) => {
  const data = useStaticQuery(compendiumMetadataQuery);
  const [structure, setStructure] = useState(prepareStructure(data.compendium.metadata.structure));
  const { isMobile, isMobileStructureOpen } = useResponsive();
  const [section, subsection, article, ...extraSegments] = pathname
    .match(/\/compendium\/(.+)/)[1]
    .split('/');
  const [chosenSection, setChosenSection] = useState(
    getInitialChosenSection(structure, section, subsection),
  );

  useEffect(() => {
    if (extraSegments.length > 1) {
      navigateToSection();

      return;
    }

    handleSectionChange(`${section}/${subsection}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraSegments.length]);

  const navigateToSection = (section = 'HTML') => {
    navigate(`/compendium/${section}/root`, { replace: true });
  };

  const handleSectionChange = (path) => {
    const [newSection, newSubsection] = path.split('/');

    if (newSubsection !== 'root') {
      triggerStructure(newSection, true);
    }

    if (chosenSection.path === path) {
      return;
    }

    try {
      setChosenSection(getSubsectionFromStructure(structure, newSection, newSubsection));
    } catch (error) {
      const mapErrorsToRedirect = {
        'Unexisting section': 'HTML',
        'Unexisting subsection': newSection,
      };
      const fallbackSection = mapErrorsToRedirect[error.message];

      navigateToSection(fallbackSection);
      setChosenSection(getSubsectionFromStructure(structure, fallbackSection, 'root'));
    }
  };

  const triggerStructure = (path, value) => {
    setStructure((currentStructure) =>
      currentStructure.map((section) =>
        section.path !== path
          ? section
          : {
              ...section,
              isOpen: typeof value !== 'undefined' ? value : !section.isOpen,
            },
      ),
    );
  };

  return (
    <div className="md:flex md:flex-nowrap h-[100svh] md:h-screen">
      <motion.div
        className="fixed top-0 left-0 z-10 md:static flex w-full md:w-auto h-full bg-white"
        variants={structureDropIn}
        animate={isMobile && !isMobileStructureOpen ? 'hidden' : 'visible'}
        transition={{
          duration: 1,
          type: 'spring',
        }}
      >
        <CompendiumStructure
          structure={structure}
          triggerStructure={triggerStructure}
          onSectionClick={handleSectionChange}
          chosenSectionPath={chosenSection.path}
        />
        <ArticleList
          articles={chosenSection.articles}
          chosenArticlePath={`${section}/${subsection}/${article}`}
        />
      </motion.div>
      <div id="article-container" className="md:grow h-full ml-[8vw] md:ml-0 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default CompendiumNavigation;
