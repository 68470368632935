import { useContext, useCallback } from 'react';

import { AuthContext } from '../context/Auth';

const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  const authenticateUser = useCallback(
    (accessToken, user) => {
      setUser(user);

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('accessToken', accessToken);
    },
    [setUser],
  );

  const deauthenticateUser = () => {
    setUser(null);

    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
  };

  return {
    user,
    isAdmin: user?.roles.includes('Admin'),
    authenticateUser,
    deauthenticateUser,
  };
};

export default useAuth;
