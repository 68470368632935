import axios from 'axios';

import { getApiUrl } from '../utils/getApiUrl';
// import { onRequest } from './private';

// todo: remove this
const onRequest = (config) => {
  const accessToken = localStorage.getItem('accessToken');

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
};

export const api = axios.create({
  baseURL: getApiUrl(),
  withCredentials: true,
});

export const signUp = async (payload) => {
  const { data } = await api.post('/sign-up', payload);

  return data;
};

export const signIn = async (payload) => {
  const { data } = await api.post('/sign-in', payload);

  return data;
};

export const signOut = async () => {
  const { data } = await api.get('/sign-out');

  return data;
};

export const activate = async (activationToken) => {
  const { data } = await api.get(`/activate/${activationToken}`);

  return data;
};
export const refresh = async () => {
  const { data } = await api.get('/refresh');

  return data;
};

api.interceptors.request.use(onRequest);
